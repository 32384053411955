<div class="title-container">
  <form class="date-selection">
    <mat-form-field class="date-selection" appearance="fill">
      <mat-label>Choose a date</mat-label>
      <input matInput
        [max]="maxDate"
        [matDatepicker]="picker"
        [ngModel]="endDateField.value"
        [ngModelOptions]="{standalone: true}"
        (dateChange)="setEndDate($event)">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <button class="primary-button" (click)="downloadExcel()">Download as Excel</button>
  </form>
</div>
<div class="table-container">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="dateTime">
      <th mat-header-cell *matHeaderCellDef> Date/Time </th>
      <td mat-cell *matCellDef="let element"> {{element.dateTime | MountainTime }} </td>
    </ng-container>

    <ng-container matColumnDef="DFEFQIT_E02_MG">
      <th mat-header-cell *matHeaderCellDef> Raw Water East Meter (KG) </th>
      <td mat-cell *matCellDef="let element"> {{element.DFEFQIT_E02_MG | number:0}} </td>
    </ng-container>

    <ng-container matColumnDef="DFWFQIT_W02_MG">
      <th mat-header-cell *matHeaderCellDef> Raw Water West Meter (KG) </th>
      <td mat-cell *matCellDef="let element"> {{element.DFWFQIT_W02_MG | number:0}} </td>
    </ng-container>

    <ng-container matColumnDef="SWRPNTOT_MG">
      <th mat-header-cell *matHeaderCellDef> Return North Meter (KG) </th>
      <td mat-cell *matCellDef="let element"> {{element.SWRPNTOT_MG | number:0}} </td>
    </ng-container>

    <ng-container matColumnDef="SWRPSTOT_MG">
      <th mat-header-cell *matHeaderCellDef> Return South Meter (KG) </th>
      <td mat-cell *matCellDef="let element"> {{element.SWRPSTOT_MG | number:0}} </td>
    </ng-container>


    <ng-container matColumnDef="ALAPSTOT_MG">
      <th mat-header-cell *matHeaderCellDef> Alameda Non-Potable PS (KG) </th>
      <td mat-cell *matCellDef="let element"> {{element.ALAPSTOT_MG | number:0}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
