<div class="title-container">
  <form class="date-selection">
    <mat-form-field class="date-selection" appearance="fill">
      <mat-label>Choose a date</mat-label>
      <input matInput
        [max]="maxDate"
        [matDatepicker]="picker"
        [ngModel]="endDateField.value"
        [ngModelOptions]="{standalone: true}"
        (dateChange)="setEndDate($event)">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <button class="primary-button" (click)="downloadExcel()">Download as Excel</button>
  </form>
</div>
<div class="chart-container">
  <highcharts-chart
    *ngIf="isDataAvailable"
    [Highcharts]="Highcharts"
    [constructorType]="chartConstructor"
    [options]="chartOptions"
    [callbackFunction]="chartCallback"
    [(update)]="updateFlag"
    [oneToOne]="oneToOneFlag"
    style="width: 100%; height: 400px; display: block;">
  </highcharts-chart>
</div>
<div class="table-container">
  <h3>Daily Flow Data for {{displayDate}}</h3>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="dateTime">
      <th mat-header-cell *matHeaderCellDef> Date/Time </th>
      <td mat-cell *matCellDef="let element"> {{element.dateTime}} </td>
    </ng-container>

    <ng-container matColumnDef="VOLUME_DIVERTED_CFS_REVISED">
      <th mat-header-cell *matHeaderCellDef align="right"> Flow Diverted (cfs)  </th>
      <td mat-cell *matCellDef="let element" align="right"> {{element.VOLUME_DIVERTED_CFS_REVISED | number:0}} </td>
    </ng-container>

    <ng-container matColumnDef="VOLUME_DIVERTED_AF">
      <th mat-header-cell *matHeaderCellDef align="right"> Volume Diverted (AF)  </th>
      <td mat-cell *matCellDef="let element" align="right"> {{element.VOLUME_DIVERTED_AF.toFixed(2)}} </td>
    </ng-container>

    <ng-container matColumnDef="VOLUME_DIVERTED">
      <th mat-header-cell *matHeaderCellDef align="right"> Volume Diverted (KG)</th>
      <td mat-cell *matCellDef="let element" align="right"> {{element.VOLUME_DIVERTED | number:0}} </td>
    </ng-container>

    <ng-container matColumnDef="VOLUME_RETURNED_CFS_REVISED">
      <th mat-header-cell *matHeaderCellDef align="right"> Flow Returned (cfs)  </th>
      <td mat-cell *matCellDef="let element" align="right"> {{element.VOLUME_RETURNED_CFS_REVISED.toFixed(2)}} </td>
    </ng-container>

    <ng-container matColumnDef="VOLUME_RETURNED_AF">
      <th mat-header-cell *matHeaderCellDef align="right"> Volume Returned (AF) </th>
      <td mat-cell *matCellDef="let element" align="right"> {{element.VOLUME_RETURNED_AF.toFixed(0) | number:0}} </td>
    </ng-container>

    <ng-container matColumnDef="VOLUME_RETURNED">
      <th mat-header-cell *matHeaderCellDef align="right"> Volume Returned (KG) </th>
      <td mat-cell *matCellDef="let element" align="right"> {{element.VOLUME_RETURNED | number}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
