import * as dayjs from 'dayjs'
import * as timezone from 'dayjs/plugin/timezone'
import * as utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
dayjs.extend(timezone)

export class DateFunctions {

  static new(date = undefined, format = undefined): dayjs.Dayjs {
    if (date === undefined) {
        return dayjs();
    }
    if (format) {
        return dayjs(date, format);
    } else {
        return dayjs(date);
    }
  }

}

