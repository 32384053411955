import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { MatTableDataSource } from '@angular/material/table';

import { DateFunctions } from '../DateFunctions';
import { environment } from '../../environments/environment';


export interface MeterElement {
  dateTime: string,
  DFEFQIT_E02_MG: string,
  DFWFQIT_W02_MG: string,
  SWRPNTOT_MG: string,
  SWRPSTOT_MG: string,
  ALAPSTOT_MG: string
}

@Component({
	selector: 'app-diversion-meter',
	templateUrl: './diversion-meter.component.html',
	styleUrls: ['./diversion-meter.component.scss']
})
export class DiversionMeterComponent implements OnInit {
	@Input() endDate: string = "";
	@Input() data = [];
  @Output() updateEndDate: EventEmitter<string> = new EventEmitter<string>();
  dataSource = new MatTableDataSource<MeterElement>();
  endDateField: FormControl;
	now = new Date();
	maxDate = this.now.toISOString().substring(0, 10);
	threeMonths = new Date(this.now.getFullYear(), this.now.getMonth() - 3, this.now.getDate());
	minDate = this.threeMonths.toISOString().substring(0, 10);
  displayedColumns: string[] = [
    'dateTime',
    'DFEFQIT_E02_MG',
    'DFWFQIT_W02_MG',
    'SWRPNTOT_MG',
    'SWRPSTOT_MG',
    'ALAPSTOT_MG'
  ];

	constructor(
    private spinner: NgxSpinnerService
	) {
	}


	ngOnInit(): void {
    var self = this;
    let dt = DateFunctions.new(self.endDate);
    self.endDateField = new FormControl(dt.toDate());
  }


  ngOnChanges(changes) {
    var self = this;
    self.dataSource.data = self.data;

    if (self.endDateField) {
      let dt = DateFunctions.new(self.endDate);
      self.endDateField = new FormControl(dt.toDate());
    }
	}


	setEndDate(event) {
    var self = this;
		let dt = event.value;
		let endDate = dt.getFullYear() + "-" + (dt.getMonth()+1) + "-" + dt.getDate();
		self.updateEndDate.emit(endDate)
	}


	updateData() {
		var self = this;
		self.startSpinner("getData");
		self.stopSpinner("getData");
	}



	//////////////////////////////////////////////////////////////////////////////////////
  downloadExcel() {
		var self = this;
		try {
			let url = `${environment.apiUrl}/rest/diversion/downloadMeterData?reportDate=${self.endDate}`;
			window.open(url, "div");
		}
		catch(err) {
			console.error(err);
		}
  }


	//////////////////////////////////////////////////////////////////////////////////////
	startSpinner(spinnerName) {
		this.spinner.show();
	}


	stopSpinner(spinnerName) {
		this.spinner.hide();
	}

}
