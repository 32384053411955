<div class="title-container">
  <form [formGroup]="dailyForm"  class="date-selection">
    <mat-form-field class="date-selection" appearance="fill">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [rangePicker]="pickerDaily" [max]="maxDate">
        <input matStartDate placeholder="Start Date" formControlName="fromDate">
        <input matEndDate placeholder="End Date" formControlName="toDate">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="pickerDaily"></mat-datepicker-toggle>
      <mat-date-range-picker #pickerDaily></mat-date-range-picker>
    </mat-form-field>
    <button class="primary-button" (click)="downloadDailyExcel()">Download Daily Data Data Excel</button>
  </form>

  <form [formGroup]="hourlyForm"  class="date-selection">
    <mat-form-field class="date-selection" appearance="fill">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [rangePicker]="pickerHourly" [max]="maxDate">
        <input matStartDate placeholder="Start" formControlName="fromDate">
        <input matEndDate placeholder="End" formControlName="toDate">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="pickerHourly"></mat-datepicker-toggle>
      <mat-date-range-picker #pickerHourly></mat-date-range-picker>
    </mat-form-field>
    <button class="primary-button" (click)="downloadHourlyExcel()">Download Hourly Data Data Excel</button>
  </form>
</div>
