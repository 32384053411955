<div class="title-container">
  <form class="date-selection">
    <mat-form-field class="date-selection" appearance="fill">
      <mat-label>Choose a date</mat-label>
      <input matInput
        [max]="maxDate"
        [matDatepicker]="picker"
        [ngModel]="endDateField.value"
        [ngModelOptions]="{standalone: true}"
        (dateChange)="setEndDate($event)">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <button class="primary-button" (click)="downloadExcel()">Download as Excel</button>
  </form>
</div>
<div class="table-container">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="dateTime">
      <th mat-header-cell *matHeaderCellDef> Date/Time </th>
      <td mat-cell *matCellDef="let element"> {{element.dateTime}} </td>
    </ng-container>

    <ng-container matColumnDef="VOLUME_DIVERTED">
      <th mat-header-cell *matHeaderCellDef align="right"> Daily Volume Diverted (KG)  </th>
      <td mat-cell *matCellDef="let element" align="right"> {{element.VOLUME_DIVERTED | number:0}} </td>
    </ng-container>

    <ng-container matColumnDef="VOLUME_DIVERTED_ACFT">
      <th mat-header-cell *matHeaderCellDef align="right"> Daily Volume Diverted (AC-FT)  </th>
      <td mat-cell *matCellDef="let element" align="right"> {{element.VOLUME_DIVERTED_ACFT | number}} </td>
    </ng-container>

    <ng-container matColumnDef="DIVERSION_TOTAL">
      <th mat-header-cell *matHeaderCellDef align="right"> Cumulative Volume Diverted (KG)</th>
      <td mat-cell *matCellDef="let element" align="right"> {{element.DIVERSION_TOTAL | number:0}} </td>
    </ng-container>

    <ng-container matColumnDef="DIVERSION_TOTAL_ACFT">
      <th mat-header-cell *matHeaderCellDef align="right"> Cumulative Volume Diverted (AC-FT) </th>
      <td mat-cell *matCellDef="let element" align="right"> {{element.DIVERSION_TOTAL_ACFT | number}} </td>
    </ng-container>

    <ng-container matColumnDef="DAILY_VOLUME_SJWT_PS">
      <th mat-header-cell *matHeaderCellDef align="right"> Daily Volume Conveyed to SJCWTP (KG) </th>
      <td mat-cell *matCellDef="let element" align="right"> {{element.DAILY_VOLUME_SJWT_PS.toFixed(0) | number:0}} </td>
    </ng-container>

    <ng-container matColumnDef="DAILY_VOLUME_SJWT_PS_ACFT">
      <th mat-header-cell *matHeaderCellDef align="right"> Daily Volume Conveyed to SJCWTP (AC-FT) </th>
      <td mat-cell *matCellDef="let element" align="right"> {{element.DAILY_VOLUME_SJWT_PS_ACFT | number}} </td>
    </ng-container>

    <ng-container matColumnDef="TOTAL_VOLUME_SJWT_PS">
      <th mat-header-cell *matHeaderCellDef align="right"> Cumulative Volume Conveyed to SJCWTP (KG) </th>
      <td mat-cell *matCellDef="let element" align="right"> {{element.TOTAL_VOLUME_SJWT_PS | number:0}} </td>
    </ng-container>

    <ng-container matColumnDef="TOTAL_VOLUME_SJWT_PS_ACFT">
      <th mat-header-cell *matHeaderCellDef align="right"> Cumulative Volume Conveyed to SJCWTP (AC-FT) </th>
      <td mat-cell *matCellDef="let element" align="right"> {{element.TOTAL_VOLUME_SJWT_PS_ACFT | number}} </td>
    </ng-container>

    <ng-container matColumnDef="DAILY_VOLUME_ALAMEDA_PS">
      <th mat-header-cell *matHeaderCellDef align="right"> Daily Volume Alameda PS (KG)</th>
      <td mat-cell *matCellDef="let element" align="right"> {{element.DAILY_VOLUME_ALAMEDA_PS | number:0}} </td>
    </ng-container>

    <ng-container matColumnDef="DAILY_VOLUME_ALAMEDA_PS_ACFT">
      <th mat-header-cell *matHeaderCellDef align="right"> Daily Volume Alameda PS (AC-FT) </th>
      <td mat-cell *matCellDef="let element" align="right"> {{element.DAILY_VOLUME_ALAMEDA_PS_ACFT | number}} </td>
    </ng-container>

    <ng-container matColumnDef="TOTAL_VOLUME_ALAMEDA_PS">
      <th mat-header-cell *matHeaderCellDef align="right"> Cumulative Volume Alameda PS (KG) </th>
      <td mat-cell *matCellDef="let element" align="right"> {{element.TOTAL_VOLUME_ALAMEDA_PS | number:0}} </td>
    </ng-container>

    <ng-container matColumnDef="TOTAL_VOLUME_ALAMEDA_PS_ACFT">
      <th mat-header-cell *matHeaderCellDef align="right"> Cumulative Volume Alameda PS (AC-FT) </th>
      <td mat-cell *matCellDef="let element" align="right"> {{element.TOTAL_VOLUME_ALAMEDA_PS_ACFT | number}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
