import { Component, OnInit, Input, Output, EventEmitter, Self } from '@angular/core';
import * as Highcharts from 'highcharts';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { set } from 'lodash';

import { DateFunctions } from '../DateFunctions';
import { environment } from '../../environments/environment';

export interface FlowElement {
	dateTime: string,
	VOLUME_DIVERTED_CFS_REVISED: number,
	VOLUME_RETURNED_CFS_REVISED: number,
	VOLUME_DIVERTED: number,
	VOLUME_RETURNED: number,
	VOLUME_DIVERTED_AF: number,
	VOLUME_RETURNED_AF: number
}

@Component({
	selector: 'app-diversion-flow',
	templateUrl: './diversion-flow.component.html',
	styleUrls: ['./diversion-flow.component.scss']
})
export class DiversionFlowComponent implements OnInit {
	@Input() endDate: string = "";
	@Input() data = [];
	@Output() updateEndDate: EventEmitter<string> = new EventEmitter<string>();
	now = new Date();
  displayDate = "";
	maxDate = this.now.toISOString().substring(0, 10);
	threeMonths = new Date(this.now.getFullYear(), this.now.getMonth() - 3, this.now.getDate());
	minDate = this.threeMonths.toISOString().substring(0, 10);
  dataSource = new MatTableDataSource<FlowElement>();
  displayedColumns: string[] = [
    'dateTime',
    'VOLUME_DIVERTED_CFS_REVISED',
    'VOLUME_DIVERTED_AF',
    'VOLUME_DIVERTED',
    'VOLUME_RETURNED_CFS_REVISED',
    'VOLUME_RETURNED_AF',
    'VOLUME_RETURNED'
  ];
  endDateField: FormControl;
  Highcharts: typeof Highcharts = Highcharts;
  isDataAvailable = false
	chartConstructor: string = 'chart';
	updateFlag: boolean = false;
	oneToOneFlag: boolean = true;
	runOutsideAngular: boolean = false;
	chartCallback: Highcharts.ChartCallbackFunction = function (chart) {};
	chartOptions: Highcharts.Options = {
		series: [{
			name: "Flow Returned (cfs)",
			data: [],
			type: 'line',
			color: '#000000',
			marker: {
				symbol: 'diamond'
			}
		},
		{
			name: "Flow Diverted (cfs)",
			data: [],
			type: 'line',
			color: '#7cb5ec',
			marker: {
				symbol: 'circle'
			}
		}],
		xAxis: {
			categories: [],
			title: {
				text: 'Time of Day'
			},
		},
		yAxis: {
			title: {
				text: 'Hourly Flow (CFS)'
      },
      min: 0,
      max: 125
		},
		title: {
			text: 'DWP Diversion and Return Flow'
		},
		legend: {
			floating: true,
			layout: 'horizontal',
			borderWidth: 1,
			backgroundColor: 'rgba(255, 255, 255, .75)',
			align: 'right',
			x: 10,
			verticalAlign: 'top',
			y: 0,
		},
		credits: {
			enabled: false
		}
	};


	constructor() { }


	ngOnInit(): void {
		var self = this;
    let dt = DateFunctions.new(self.endDate);
    self.displayDate = dt.format("MM/DD/YYYY");
    self.endDateField = new FormControl(dt.toDate());
	}


	ngOnChanges(changes) {
		var self = this;
		let returnList = [];
		let divertList = [];
		let legendList = [];
		for (let flowItem of self.data) {
      legendList.push(flowItem.dateTime);
		  var rItem = [];
			rItem.push(flowItem.dateTimeFull);
			rItem.push(flowItem.VOLUME_RETURNED_CFS_REVISED);
			returnList.push( rItem) ;
			var dItem = [];
			dItem.push(flowItem.dateTimeFull);
			dItem.push(flowItem.VOLUME_DIVERTED_CFS_REVISED);
			divertList.push( dItem) ;
		}
	  set( self.chartOptions, "series[0].data", returnList);
    set( self.chartOptions, "series[1].data", divertList);
    set( self.chartOptions, "xAxis.categories", legendList);
    self.isDataAvailable = true;
    self.updateFlag = true;

    self.dataSource.data = self.data;

    let endDateDt = DateFunctions.new(self.endDate);
    self.displayDate = endDateDt.format("MM/DD/YYYY");
    console.log("setEndDate", self.endDate, self.displayDate, endDateDt, endDateDt.toDate());
    if (self.endDateField) {
      self.endDateField = new FormControl(endDateDt.toDate());
    }
  }




	setEndDate(event) {
    var self = this;
		let dt = event.value;
		let endDate = dt.getFullYear() + "-" + (dt.getMonth()+1) + "-" + dt.getDate();
		self.updateEndDate.emit(endDate)
  }




	//////////////////////////////////////////////////////////////////////////////////////
  downloadExcel() {
		var self = this;
		try {
			let url = `${environment.apiUrl}/rest/diversion/downloadFlowData?reportDate=${self.endDate}`;
			window.open(url, "div");
		}
		catch(err) {
			console.error(err);
		}
  }

}
