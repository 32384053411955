import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxSpinnerModule } from "ngx-spinner";
import { HighchartsChartModule } from 'highcharts-angular';

import { DiversionFlowComponent } from './diversion-flow/diversion-flow.component';
import { DiversionMeterComponent } from './diversion-meter/diversion-meter.component';
import { DownloadComponent } from './download/download.component';
import { DiversionVolumeComponent } from './diversion-volume/diversion-volume.component';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DateNMPipe } from './DateNM.pipe';

@NgModule({
	declarations: [
		AppComponent,
		DiversionFlowComponent,
		DiversionMeterComponent,
		DiversionVolumeComponent,
		DownloadComponent,
    DateNMPipe
	],
	imports: [
    HttpClientModule,
		MatTabsModule,
		MatTableModule,
		MatDatepickerModule,
		FormsModule,
		ReactiveFormsModule,
		MatNativeDateModule,
		MatInputModule,
		MatFormFieldModule,
		NgxSpinnerModule,
		HighchartsChartModule,
		BrowserModule,
		BrowserAnimationsModule
	],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule { }
