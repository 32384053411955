import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { MatTableDataSource } from '@angular/material/table';

import { DateFunctions } from '../DateFunctions';
import { environment } from '../../environments/environment';


export interface VolumeElement {
  dateTime: string,
  DFEFQIT_E02_MG: string,
  DFWFQIT_W02_MG: string,
  SWRPNTOT_MG: string,
  SWRPSTOT_MG: string,
  ALAPSTOT_MG: string
}

@Component({
  selector: 'app-diversion-volume',
  templateUrl: './diversion-volume.component.html',
  styleUrls: ['./diversion-volume.component.scss']
})
export class DiversionVolumeComponent implements OnInit {

	@Input() endDate: string = "";
	@Input() data = [];
  @Output() updateEndDate: EventEmitter<string> = new EventEmitter<string>();
  dataSource = new MatTableDataSource<VolumeElement>();  endDateField: FormControl;
	now = new Date();
	maxDate = this.now.toISOString().substring(0, 10);
	threeMonths = new Date(this.now.getFullYear(), this.now.getMonth() - 3, this.now.getDate());
	minDate = this.threeMonths.toISOString().substring(0, 10);
  displayedColumns: string[] = [
    'dateTime',
    "VOLUME_DIVERTED",
    "VOLUME_DIVERTED_ACFT",
    "DIVERSION_TOTAL",
    "DIVERSION_TOTAL_ACFT",
    "DAILY_VOLUME_SJWT_PS",
    "DAILY_VOLUME_SJWT_PS_ACFT",
    "TOTAL_VOLUME_SJWT_PS",
    "TOTAL_VOLUME_SJWT_PS_ACFT",
    "DAILY_VOLUME_ALAMEDA_PS",
    "DAILY_VOLUME_ALAMEDA_PS_ACFT",
    "TOTAL_VOLUME_ALAMEDA_PS",
    "TOTAL_VOLUME_ALAMEDA_PS_ACFT"
  ];

	constructor(
    private spinner: NgxSpinnerService
	) {
	}


	ngOnInit(): void {
    var self = this;
    let dt = DateFunctions.new(self.endDate);
    self.endDateField = new FormControl(dt.toDate());
  }


  ngOnChanges(changes) {
    var self = this;
    self.dataSource.data = self.data;

    if (self.endDateField) {
      let dt = DateFunctions.new(self.endDate);
      self.endDateField = new FormControl(dt.toDate());
    }
	}


	setEndDate(event) {
    var self = this;
		let dt = event.value;
		let endDate = dt.getFullYear() + "-" + (dt.getMonth()+1) + "-" + dt.getDate();
		self.updateEndDate.emit(endDate)
	}


	updateData() {
		var self = this;
		self.startSpinner("getData");
		self.stopSpinner("getData");
  }


	//////////////////////////////////////////////////////////////////////////////////////
  downloadExcel() {
		var self = this;
		try {
			let url = `${environment.apiUrl}/rest/diversion/downloadVolumeData?reportDate=${self.endDate}`;
			window.open(url, "div");
		}
		catch(err) {
			console.error(err);
		}
  }


	//////////////////////////////////////////////////////////////////////////////////////
	startSpinner(spinnerName) {
		this.spinner.show();
	}


	stopSpinner(spinnerName) {
		this.spinner.hide();
	}

}
