import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { DateFunctions } from '../DateFunctions';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {
	now = new Date();
	maxDate = this.now.toISOString().substring(0, 10);
	threeMonths = new Date(this.now.getFullYear(), this.now.getMonth() - 3, this.now.getDate());
	minDate = this.threeMonths.toISOString().substring(0, 10);
  dailyForm: FormGroup;
  hourlyForm: FormGroup;


  constructor(
    private formBuilder: FormBuilder
  ) {
  }


  ngOnInit(): void {
    var self = this;
    self.dailyForm = self.formBuilder.group({
      fromDate: new FormControl('', Validators.required),
      toDate: new FormControl('', Validators.required)
    });
    self.hourlyForm = self.formBuilder.group({
      fromDate: new FormControl('', Validators.required),
      toDate: new FormControl('', Validators.required)
    });
  }


  downloadDailyExcel() {
    var self = this;
    let start = DateFunctions.new(self.dailyForm.value.fromDate).format("YYYY-MM-DD");
    let end =  DateFunctions.new(self.dailyForm.value.toDate).format("YYYY-MM-DD");
		try {
      let url = `${environment.apiUrl}/rest/diversion/downloadDiversionDaily?startTime=${start}&endTime=${end}`;
      console.log("url", url) ;

      let duration = (self.dailyForm.value.toDate - self.dailyForm.value.fromDate);
      let durationDays = duration / 86400000;
      if (durationDays > 93) {
        alert("Warning: Can't download more than three months of data at a time");
        return;
      }
			window.open(url, "div");
		}
		catch(err) {
			console.error(err);
		}
  }


  downloadHourlyExcel() {
    var self = this;
    let start = DateFunctions.new(self.hourlyForm.value.fromDate).format("YYYY-MM-DD") + " 00:00:00";
    let end =  DateFunctions.new(self.hourlyForm.value.toDate).format("YYYY-MM-DD") + " 23:59:59";
		try {
      let url = `${environment.apiUrl}/rest/diversion/downloadDiversionHourly?startTime=${start}&endTime=${end}`;
      console.log("url", url) ;

      let duration = (self.dailyForm.value.toDate - self.dailyForm.value.fromDate);
      let durationDays = duration / 86400000;
      if (durationDays > 93) {
        alert("Warning: Can't download more than three months of data at a time");
        return;
      }

			window.open(url, "div");
		}
		catch(err) {
			console.error(err);
		};
  }

}
