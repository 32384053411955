import { Component } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { get } from 'lodash';

import { DateFunctions } from './DateFunctions';
import { DiversionService } from './diversion.service';


@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	providers: [
	DiversionService,
	NgxSpinnerService
	]
})
export class AppComponent {
	public mode = "Meter";
	public startDate = '2020-11-23';
	public endDate = DateFunctions.new().format('YYYY-MM-DD');
	rawResults = [];
	public meterData = [];
	public volumeData = [];
	public flowData = [];

	constructor(
		private diversionService: DiversionService,
		private spinner: NgxSpinnerService,
	) {
	}


	ngOnInit(): void {
		var self = this;
		self.updateData();
	}


	changeMode(newMode) {
		var self = this;
		self.mode = newMode;
	}


	setEndDate(dt) {
		var self = this;
		self.endDate = dt;
		self.updateData() ;
	}


	updateData() {
		var self = this;
		var dt = DateFunctions.new(self.endDate) ;
		let year = dt.year();
		let month = parseInt(dt.format("M"));
    let day = dt.date();

    self.rawResults = [];
	  self.meterData = [];
	  self.volumeData = [];
	  self.flowData = [];

		self.startSpinner("getHourlyDataForDate");
		self.diversionService.getHourlyDataForDate(year, month, day)
			.then(resp => {
				self.stopSpinner("getHourlyDataForDate");
				if (!resp) {
					return;
				}

        self.rawResults = get(resp, "results.results", []);
        var hResults = [];
        var vResults = [];
        var fResults = [];
        var vSummary = {
          VOLUME_DIVERTED: 0,
          DAILY_VOLUME_SJWT_PS: 0,
          DAILY_VOLUME_ALAMEDA_PS: 0,
        };
        let startHour = DateFunctions.new(self.endDate + " 00:00:00Z");
        startHour = startHour.utc();
        let endHour = startHour.add(24,'hour');
				for (let r of self.rawResults) {
          try {
            let dtStr = get(r, "datetime_minus1hour", "") ;
            let dt = DateFunctions.new(dtStr);
            dt = dt.utc();
            if ((dt < startHour) || (dt >= endHour)) {
              continue;
            }

            let h = {
              ts: dt.valueOf(),
              dt: dtStr,
              date: dt.format("YYYY-MM-DD"),
              dateTime: dt.format("hh:mm a"),
              DFEFQIT_E02_MG: get(r, "analog.DFEFQIT_E02_MG.curval", 0),
              DFWFQIT_W02_MG: get(r, "analog.DFWFQIT_W02_MG.curval", 0),
              SWRPNTOT_MG: get(r, "analog.SWRPNTOT_MG.curval", 0),
              SWRPSTOT_MG: get(r, "analog.SWRPSTOT_MG.curval", 0),
              ALAPSTOT_MG: get(r, "analog.ALAPSTOT_MG.curval", 0)*1000,
              ALAPSTOT_AF: get(r, "analog.ALAPSTOT_AF.curval", 0)
            };
            hResults.push(h);

            let v = {
              ts: dt.valueOf(),
              dt: dtStr,
              date: dt.format("YYYY-MM-DD"),
              dateTime: dt.format("hh:mm a"),
              VOLUME_DIVERTED: get(r, "VOLUME_DIVERTED", 0),
              VOLUME_DIVERTED_ACFT: (get(r, "VOLUME_DIVERTED", 0) / 325.851).toFixed(2),
              DIVERSION_TOTAL: get(r, "DIVERSION_TOTAL", 0),
              DIVERSION_TOTAL_ACFT: (get(r, "DIVERSION_TOTAL", 0) / 325.851).toFixed(2),
              DAILY_VOLUME_SJWT_PS: get(r, "VOLUME_DIVERTED_CFS" , 0) * 651.702,
              DAILY_VOLUME_SJWT_PS_ACFT: get(r, "VOLUME_DIVERTED_CFS", 0).toFixed(2),
              TOTAL_VOLUME_SJWT_PS: get(r, "DIVERSION_TOTAL_minus1hour", 0),
              TOTAL_VOLUME_SJWT_PS_ACFT: (get(r, "DIVERSION_TOTAL_minus1hour", 0) / 325.851).toFixed(2),
              DAILY_VOLUME_ALAMEDA_PS: get(r, "DAILY_VOLUME_ALAMEDA_PS", 0) * 1000,
              DAILY_VOLUME_ALAMEDA_PS_ACFT: ((get(r, "DAILY_VOLUME_ALAMEDA_PS", 0) * 1000) / 325.851).toFixed(2),
              TOTAL_VOLUME_ALAMEDA_PS: get(r, "analog.ALAPSTOT_AF.curval", 0) * 325.851,
              TOTAL_VOLUME_ALAMEDA_PS_ACFT: get(r, "analog.ALAPSTOT_AF.curval", 0).toFixed(2)
            };
            vResults.push(v);
            vSummary.VOLUME_DIVERTED += v.VOLUME_DIVERTED ;
            vSummary.DAILY_VOLUME_SJWT_PS += v.DAILY_VOLUME_SJWT_PS ;
            vSummary.DAILY_VOLUME_ALAMEDA_PS += v.DAILY_VOLUME_ALAMEDA_PS ;

            let f = {
              ts: dt.valueOf(),
              dt: dtStr,
              date: dt.format("YYYY-MM-DD"),
              dateTime: dt.format("hh:mm a"),
              dateTimeFull: dt.format("YYYY-MM-DD hh:mm a"),
              VOLUME_DIVERTED_CFS_REVISED: get(r, "VOLUME_DIVERTED_CFS_REVISED", 0),
              VOLUME_RETURNED_CFS_REVISED: get(r, "VOLUME_RETURNED_CFS_REVISED", 0),
              VOLUME_DIVERTED:  get(r, "VOLUME_DIVERTED", 0),
              VOLUME_RETURNED: get(r, "VOLUME_RETURNED", 0),
              VOLUME_DIVERTED_AF: get(r, "VOLUME_DIVERTED_AF", 0),
              VOLUME_RETURNED_AF: get(r, "VOLUME_RETURNED_AF", 0)
            };
            fResults.push(f);
          }
          catch(err) {
            console.log(err);
          }
        }
        self.setMeterData(hResults);
        self.setVolumeData(vResults, vSummary);
        self.setFlowData(fResults);
			})
			.catch(ex => {
				self.stopSpinner("getHourlyDataForDate");
			});
		}


    setMeterData(hResults) {
      var self = this;
      self.meterData = hResults.sort((a,b) => {
        if (a.ts < b.ts) {
          return -1;
        }
        else if (a.ts > b.ts) {
          return 1;
        }
        else {
          return 0;
        }
      });
    }


    setVolumeData(vResults, vSummary) {
      var self = this;
      self.volumeData = vResults.sort((a,b) => {
        if (a.ts < b.ts) {
          return -1;
        }
        else if (a.ts > b.ts) {
          return 1;
        }
        else {
          return 0;
        }
      });
      let summary = {
        dateTime: "summary",
        VOLUME_DIVERTED: vSummary.VOLUME_DIVERTED,
        VOLUME_DIVERTED_ACFT: (vSummary.VOLUME_DIVERTED / 325.851).toFixed(2),
        DIVERSION_TOTAL: null,
        DIVERSION_TOTAL_ACFT: null,
        DAILY_VOLUME_SJWT_PS: vSummary.DAILY_VOLUME_SJWT_PS,
        DAILY_VOLUME_SJWT_PS_ACFT:(vSummary.DAILY_VOLUME_SJWT_PS / 325.851).toFixed(2),
        TOTAL_VOLUME_SJWT_PS: null,
        TOTAL_VOLUME_SJWT_PS_ACFT: null,
        DAILY_VOLUME_ALAMEDA_PS: vSummary.DAILY_VOLUME_ALAMEDA_PS,
        DAILY_VOLUME_ALAMEDA_PS_ACFT: (vSummary.DAILY_VOLUME_ALAMEDA_PS / 325.851).toFixed(2),
        TOTAL_VOLUME_ALAMEDA_PS: null,
        TOTAL_VOLUME_ALAMEDA_PS_ACFT: null
      };
      self.volumeData.push(summary);
    }


    setFlowData(fResults) {
      var self = this;
      self.flowData = fResults.sort((a,b) => {
        if (a.ts < b.ts) {
          return -1;
        }
        else if (a.ts > b.ts) {
          return 1;
        }
        else {
          return 0;
        }
      });
    }

	//////////////////////////////////////////////////////////////////////////////////////

	startSpinner(spinnerName) {
		this.spinner.show();
	}


	stopSpinner(spinnerName) {
		this.spinner.hide();
  }

}
